<template>
  <div class="apartment-detail">
    <app-detail :opt="opt"></app-detail>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      motherfucker:[],
      opt: {
        id: "",
        title: "转账详情",
        form: [
          {
            label: "提款流水号",
            key: "withdrawId",
            disabled: true,
            isCol50: true
          },
          {
            label: "提款人",
            key: "withdrawAdult",
            disabled: true,
            isCol50: true
          },
          {
            label: "提款人手机",
            key: "withdrawPhone",
            disabled: true,
            isCol50: true
          },
          {
            label: "提款时间",
            key: "withdrawTime",
            disabled: true,
            isCol50: true
          },
          {
            label: "提款金额",
            key: "withdrawAmount",
            disabled: true,
            isCol50: true
          },
          // {
          //   label: "转账情况",
          //   key: "transferState",
          //   disabled: true,
          //   isCol50: true
          // },
          // {
          //   label: "到账时间",
          //   key: "reconDateStr",
          //   isCol50: true,
          //   disabled: true,
          //   type: 'date-time'
          // },
          {
            label: "提款说明",
            type: "textarea",
            disabled: true,
            key: "rentalFee",
            isCol50: true
          },
          {
            label: "转账金额",
            key: "transferAmount",
            disabled: true
          },
          {
            label: "上传凭证",
            key: "transferPath",
            ufo:1,
            type: "upload-img-form",
            rules: [{ required: true }],
            opt: {
              url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
              tip: "上传图片文件到账凭证",
              limit:1,
              upload(file) {
                console.log(file)
                return true;
              },
              remove(file, fileList) {
                return true;
              }
            }
          },
          {
            label: "转账说明",
            key: "remark",
            type: "textarea"
          }
        ],
        buttons: [
          
          {
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              let dto = {
                transferRemark:form.remark ? form.remark : '',
                withdrawId: form.withdrawId,
                transferPath: form.transferPath ? form.transferPath[0]['url'] : ''
              };
              let url = "finance-service/withdraw/transfer";
              let message = "编辑成功";
              console.log(dto)
              _this.post(url, dto).then(function() {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                _this.$back();
              });
            }
          
          }
        ],
        get(opt) {
          var data = _this.$route.query;
          opt.cb({
            withdrawId: data.withdrawId,
            transferPath: [],
            // reconDateStr: "预计到账时间:" + data.reconDate,
            // reconDateStr: data.reconDate,
            reconState: data.reconState,
            // transferState: data.transferState == '1' ? "未到账" : "",
            withdrawAdult: data.withdrawAdult,
            withdrawPhone: data.withdrawPhone,
            transferAmount: "￥" + data.withdrawAmount,
            withdrawAmount: "￥" + data.withdrawAmount,
            withdrawTime: data.withdrawTime,
            rentalFee: '入驻威富智慧公寓平台后，从平台提取房客缴纳房租，水电费，押金等费用，不扣取任何服务费用，提款申请提交成功后，正常在1-3个工作日后到账。'
          });
        }
      }
    };
  },
  created() {
    this.id = this.$route.params.id || "";
  }
};
</script>

